import { call, put, takeLatest } from 'redux-saga/effects';
import { AntecipacaoDeleteContaApi, AntecipacaoEmprestimosGetApi, AntecipacaoGetApi, AntecipacaoPostApi } from '../../target-api/api';
import { message } from 'antd';
import { fetchAntecipacaoDeleteContaFailure, fetchAntecipacaoDeleteContaSuccess, fetchAntecipacaoEmprestimosGetFailure, fetchAntecipacaoEmprestimosGetSuccess, fetchAntecipacaoGetFailure, fetchAntecipacaoGetSuccess, fetchAntecipacaoPostFailure, fetchAntecipacaoPostSuccess } from './actions';
import { FETCH_ANTECIPACAO_DELETE_CONTA_DATA, FETCH_ANTECIPACAO_EMPRESTIMOS_DATA, FETCH_ANTECIPACAO_GET_DATA, FETCH_ANTECIPACAO_POST_DATA } from './types';

export function* fetchAntecipacaoGetSaga(): Generator<any, void, any> {
  try {
    const AntecipacaoResponse = yield call(AntecipacaoGetApi);
    if (AntecipacaoResponse && AntecipacaoResponse.length <= 0) {
      message.warning('Nenhum registro encontrado!');
      yield put(fetchAntecipacaoGetSuccess([]));
    } else {
      yield put(fetchAntecipacaoGetSuccess(AntecipacaoResponse));
    }
  } catch (error: any) {
    yield put(fetchAntecipacaoGetFailure(error.message));
  }
}

export function* fetchAntecipacaoPostSaga(action: any): Generator<any, void, any> {
  try {
    const AntecipacaoResponse = yield call(AntecipacaoPostApi, action.payload.json_data);
    if (AntecipacaoResponse && AntecipacaoResponse.length <= 0) {
      message.warning('Não foi possível enviar os dados!');
      yield put(fetchAntecipacaoPostSuccess([]));
    } else {
      yield put(fetchAntecipacaoPostSuccess(AntecipacaoResponse));
    }
  } catch (error: any) {
    yield put(fetchAntecipacaoPostFailure(error.message));
  }
}

export function* fetchAntecipacaoDeleteContaSaga(action: { type: string, payload: number }): Generator<any, void, any> {
  try {
    const conta_id = action.payload;
    const AntecipacaoResponse = yield call(AntecipacaoDeleteContaApi, conta_id);
    if (AntecipacaoResponse && AntecipacaoResponse.length <= 0) {
      message.warning('Nnehuma conta encontrada.');
      yield put(fetchAntecipacaoDeleteContaSuccess([]));
    } else {
      yield put(fetchAntecipacaoDeleteContaSuccess(AntecipacaoResponse));
    }
  } catch (error: any) {
    yield put(fetchAntecipacaoDeleteContaFailure(error.message));
  }
}

export function* fetchAntecipacaoEmprestimosGetSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date, page, pageSize, filters, sortField, sortOrder } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const AntecipacaoEmprestimoResponse = yield call(AntecipacaoEmprestimosGetApi, company_id, start_date, end_date, page, pageSize, filters, sortField, sortOrder);
    if (AntecipacaoEmprestimoResponse && AntecipacaoEmprestimoResponse.length <= 0) {
      message.warning('Nenhum registro de empréstimo encontrado!');
      yield put(fetchAntecipacaoEmprestimosGetSuccess([]));
    } else {
      yield put(fetchAntecipacaoEmprestimosGetSuccess(AntecipacaoEmprestimoResponse));
    }
  } catch (error: any) {
    yield put(fetchAntecipacaoEmprestimosGetFailure(error.message));
  }
}


export function* watchAntecipacaoGetSaga() {
  yield takeLatest(FETCH_ANTECIPACAO_GET_DATA, fetchAntecipacaoGetSaga);
  yield takeLatest(FETCH_ANTECIPACAO_POST_DATA, fetchAntecipacaoPostSaga);
  yield takeLatest(FETCH_ANTECIPACAO_DELETE_CONTA_DATA, fetchAntecipacaoDeleteContaSaga);
  yield takeLatest(FETCH_ANTECIPACAO_EMPRESTIMOS_DATA, fetchAntecipacaoEmprestimosGetSaga);
}
