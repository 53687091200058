import React, { useState, useEffect } from 'react';
import { Layout, Menu, Drawer } from 'antd';
import {
  AuditOutlined,
  BankOutlined,
  BarChartOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  CheckSquareOutlined,
  DollarOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  FileTextOutlined,
  FundOutlined,
  FundViewOutlined,
  ImportOutlined,
  MenuOutlined,
  PercentageOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import card from '../../assets/login/logo-card-union.svg';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import PortalAjuda from '../PortalAjuda';
import SubMenu from 'antd/es/menu/SubMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import AntecipacaoModal from '../Antecipacao';

const { Sider } = Layout;

const Sidebar: React.FC<any> = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');
  const location = useLocation();
  const user = useSelector((state: RootState) => state.usuario.profile);
  const userPermissions = user?.permissions || [];
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 1000);

      if (window.innerWidth > 1000 && visible) {
        setVisible(false);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [visible]);

  useEffect(() => {
    const pathname = location.pathname;
    setSelectedMenuItem(pathname);
  }, [location]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const shouldShowText = !collapsed || isMobile;

  const content = (
    <Menu mode="inline" selectedKeys={[selectedMenuItem]} style={{ height: '100%' }} onClick={isMobile ? () => setVisible(false) : undefined}>
      <img style={{ padding: 20 }} src={card} alt="Card Logo" />
      <Menu.Item title="Dashboard" key="/dashboard" icon={<BarChartOutlined style={{ backgroundColor: '#252f64', color: 'white', borderRadius: 5, fontSize: 20, padding: 5 }} />}>
        <NavLink to="/dashboard">{shouldShowText && 'Dashboard'}</NavLink>
      </Menu.Item>
      <Menu.Item title="Vendas" key="/venda" icon={<ReadOutlined style={{ backgroundColor: '#252f64', color: 'white', borderRadius: 5, fontSize: 20, padding: 5 }} />}>
        <NavLink to="/venda">{shouldShowText && 'Vendas'}</NavLink>
      </Menu.Item>
      <SubMenu key="gestao-pagamento" icon={<CarryOutOutlined style={{ backgroundColor: '#252f64', color: 'white', borderRadius: 5, fontSize: 20, padding: 5 }} />} title={shouldShowText && 'Pagamentos'}>
        <Menu.Item key="/pagamento" icon={<CheckSquareOutlined />}>
          <NavLink to="/pagamento">Pagamentos</NavLink>
        </Menu.Item>
        <Menu.Item key="/agenda-recebimentos" icon={<CalendarOutlined />}>
          <NavLink to="/agenda-recebimentos">Recebíveis</NavLink>
        </Menu.Item>
        <Menu.Item key="/pagamento-consolidado" icon={<CarryOutOutlined />}>
          <NavLink to="/pagamento-consolidado">Consolidados</NavLink>
        </Menu.Item>
      </SubMenu>
      {userPermissions.includes('BANKING_ACCESS') && (
        <SubMenu key="banking" icon={<BankOutlined style={{ backgroundColor: '#252f64', color: 'white', borderRadius: 5, fontSize: 20, padding: 5 }} />} title={shouldShowText && 'Bancária'}>
          <Menu.Item key="/banking-ofx" icon={<ImportOutlined />}>
            <NavLink to="/banking-ofx">Arquivo(s) OFX</NavLink>
          </Menu.Item>
        </SubMenu>
      )}
      <SubMenu key="gestao-cliente" icon={<FundOutlined style={{ backgroundColor: '#252f64', color: 'white', borderRadius: 5, fontSize: 20, padding: 5 }} />} title={shouldShowText && 'Analytics'}>
        <Menu.Item key="/analise-vendas" icon={<FundViewOutlined />}>
          <NavLink to="/analise-vendas">Vendas</NavLink>
        </Menu.Item>
        <Menu.Item key="/analise-pagamentos" icon={<FundViewOutlined />}>
          <NavLink to="/analise-pagamentos">Pagamentos</NavLink>
        </Menu.Item>
      </SubMenu>
      <Menu.Item title="Gestão de Taxas" key="/gestao-taxa" icon={<PercentageOutlined style={{ backgroundColor: '#252f64', color: 'white', borderRadius: 5, fontSize: 20, padding: 5 }} />}>
        <NavLink to="/gestao-taxa">{shouldShowText && 'Taxas'}</NavLink>
      </Menu.Item>
      {userPermissions.includes('ANTECIPATION_ACCESS') && (
        <SubMenu key="antecipacao" icon={<AuditOutlined style={{ backgroundColor: '#252f64', color: 'white', borderRadius: 5, fontSize: 20, padding: 5 }} />} title={shouldShowText && 'Antecipação'}>
          <Menu.Item key="/antecipacao" onClick={showModal} icon={<DollarOutlined />}>
            Antecipar
          </Menu.Item>
          <Menu.Item key="/antecipacao/emprestimos" icon={<FileTextOutlined />}>
          <NavLink to="/antecipacao/emprestimos">Antecipações</NavLink>
          </Menu.Item>
        </SubMenu>
      )}
      {!collapsed && <PortalAjuda />}
      <AntecipacaoModal isVisible={isModalVisible} onClose={handleCloseModal} />
    </Menu>
  );
  return (
    <>
      {isMobile && (
        <>
          <Drawer placement="left" closable={false} onClose={onClose} visible={visible} bodyStyle={{ padding: 0 }} width={isMobile ? 250 : undefined}>
            {content}
          </Drawer>
          <MenuOutlined onClick={showDrawer} style={{ padding: '10px', fontSize: '15px', lineHeight: '32px', cursor: 'pointer' }} />
        </>
      )}
      {!isMobile && (
        <Sider className={collapsed ? 'sider-collapsed' : ''} style={{ backgroundColor: 'white' }} width={250} collapsed={collapsed} onCollapse={toggleCollapsed}>
          <div style={{ backgroundColor: 'white' }}>
            {collapsed ? (
              <DoubleRightOutlined onClick={toggleCollapsed} style={{ padding: '10px', fontSize: '20px', cursor: 'pointer', color: '#252f64' }} />
            ) : (
              <DoubleLeftOutlined onClick={toggleCollapsed} style={{ padding: '10px', fontSize: '20px', cursor: 'pointer', color: '#252f64' }} />
            )}
            {content}
          </div>
        </Sider>
      )}
    </>
  );
};

export default Sidebar;
