import { Col, Row, Skeleton } from 'antd';
import AnimatedBanner from '../../components/AnimatedBanner';
import MessageHeader from '../../components/MessageHeader';
import { Container } from '../../styles/GlobalStyles';
import { StatCard } from '../../components/Charts/StatCard';
import { DollarCircleOutlined, FieldNumberOutlined, ReloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { useEffect, useState } from 'react';
import TableComponent from '../../components/TablePagination';
import { renderAntecipacaoEmprestimos, textAntecipacaoEmprestimos } from './interfaces';
import { mapFiltersAntecipacaoEmprestimosForApi } from '../../utils/validateFilter';
import { fetchAntecipacaoEmprestimosGet } from '../../store/states/antecipacao/actions';
import { columnsAntecipacaoEmprestimos } from './interfaces/columnsAntecipacaoEmprestimos';
import { filter } from 'lodash';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';

function AntecipacaoEmprestimosScreen() {
  const dispatch = useDispatch();
  const [showAcquirer, setShowAcquirer] = useState(() => {
    const persistedState = localStorage.getItem('showAcquirerEmprestimos');
    return persistedState ? JSON.parse(persistedState) : true;
  });
  const loading = useSelector((state: RootState) => state.antecipacao.loading_emprestimos);
  const emprestimos = useSelector((state: RootState) => state.antecipacao.data_emprestimos_get);
  const companyIDs = useSelector((state: RootState) => state.empresa.companyID);
  const startDate = useSelector((state: RootState) => state.datas.startDate);
  const endDate = useSelector((state: RootState) => state.datas.endDate);
  const bancos = useSelector((state: RootState) => state.banco.data);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [perPage] = useState(10);
  const [emprestimosTotal, setEmprestimosTotal] = useState(0);
  const [emprestimosValorLiquidoTotal, setemprestimosValorLiquidoTotal] = useState(0);
  const [resetTableFilters, setResetTableFilters] = useState(false);
  const [tableKey, setTableKey] = useState(0);
  const [contas, setContas] = useState([]);
  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {
    setEmprestimosTotal(emprestimos?.filtered_values?.qtde_emprestimo);
    setemprestimosValorLiquidoTotal(emprestimos?.unfiltered_values?.soma_emprestimos);
    setContas(emprestimos?.unfiltered_values?.contas);
    setEmpresas(emprestimos?.unfiltered_values?.empresas);
  }, [emprestimos]);

  useEffect(() => {
    localStorage.setItem('showAcquirerEmprestimos', JSON.stringify(showAcquirer));
  }, [showAcquirer]);

  // useEffect(() => {
  //   console.log('emprestimos', emprestimos);
  // }, [emprestimos]);

  const fetchEmprestimos = (page: number, pageSize: number, sortField: any, sortOrder: any, filters: any) => {
    const order = sortOrder !== undefined && sortOrder === 'ascend' ? 'ASC' : 'DESC';
    const apiFilters = mapFiltersAntecipacaoEmprestimosForApi(filters);
    dispatch(
      fetchAntecipacaoEmprestimosGet({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        page,
        pageSize,
        filters: apiFilters,
        sortField,
        sortOrder: order,
      }),
    );
  };

  const handlePageChange = (page: number = 1, pageSize: number = 10, sortField: string = 'antecipacaoemprestimodataassinatura', sortOrder: string = 'DESC', filters: any) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchEmprestimos(page, pageSize, sortField, sortOrder, filters);
  };

  const handleRefresh = () => {
    localStorage.removeItem('showAcquirerEmprestimos');
    setFilters({});
    setCurrentPage(1);
    setShowAcquirer(true);
    setResetTableFilters((prev) => !prev);
    fetchEmprestimos(1, pageSize, 'antecipacaoemprestimodataassinatura', 'DESC', {});
    setTableKey((prevKey) => prevKey + 1);
  };

  const updateFilters = (newFilters: any) => {
    setFilters(newFilters);
  };

  return (
    <AnimatedBanner>
      <Container>
        <>
          <MessageHeader textMessage={textAntecipacaoEmprestimos} />
          <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
            <Col flex="5">
              <StatCard loadingData={loading} title="Antecipações Realizadas" value={emprestimosTotal || 0} icon={<FieldNumberOutlined />} />
            </Col>
            <Col flex="5">
              <StatCard
                loadingData={loading}
                title="Total Valor Líquido"
                value={(emprestimosValorLiquidoTotal || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
          </Row>
          {loading ? (
            <Skeleton active />
          ) : (
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, marginBottom: 10, gap: 5 }}>
                {emprestimos.list && (
                  <ButtonRefreshList loading={loading} icon={<ReloadOutlined />} onClick={handleRefresh}>
                    Limpar Filtros
                  </ButtonRefreshList>
                )}
              </div>
            </>
          )}

          <TableComponent
            key={tableKey}
            loadingData={loading}
            data={emprestimos.list && emprestimos.list.length > 0 ? emprestimos.list : []}
            columns={columnsAntecipacaoEmprestimos(contas, empresas, bancos)}
            rowKeyId="antecipacaoemprestimoid"
            rowRender={renderAntecipacaoEmprestimos}
            perPage={perPage}
            currentPage={currentPage}
            totalPages={emprestimosTotal}
            onChangePage={handlePageChange}
            expand={false}
            externalFilters={filters}
            resetFilters={resetTableFilters}
            updateFilters={updateFilters}
            subtotais={false}
          />
        </>
      </Container>
    </AnimatedBanner>
  );
}

export default AntecipacaoEmprestimosScreen;
