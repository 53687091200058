import styled from 'styled-components';
import { Modal, Button, Form } from 'antd';
import ReactInputMask from 'react-input-mask';

// Modal estilizado
export const CustomModal = styled(Modal)`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    min-width: 800px;
    width: auto;
    height: auto;
    left: -25%;
    top: -30%;
  }

  .ant-select-selector {
    border-radius: 5px;
    border: 1px solid #000;
  }

  .ant-modal-body {
    margin: 10px 50px;
  }

  .ant-modal-body-h2 {
    font-size: 14px;
    text-align: center;
    font-family: 'Poppins',sans-serif !important;

  }
`;

export const CustomFormModalSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 14px;
    text-align: center;
    padding: 20px;
    font-family: 'Poppins',sans-serif !important;
  }

  .ant-row {
    width: 500px;
  }
`;

export const CustomFormModalSelectConta = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  }
`;

export const CustomConteinerFormModal = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 35px;

  h2 {
    font-size: 14px;
    text-align: center;
    padding: 20px;
    margin: 0px;
    font-family: 'Poppins',sans-serif !important;
  }
`;

export const CustomConteinerFormModalDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: last baseline;

  .ant-form-item {
    width: 100%;
  }

  .ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-explain-error {
    font-size: 12px;
  }
`;

export const ButtonConcluir = styled(Button)`
  paddin: 50px !important;
  background: linear-gradient(135deg, #536390, #252f64) !important;
  color: white !important;
  border: none !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  &:hover,
  &:focus {
    background: linear-gradient(135deg, #252f64, #536390) !important;
    color: #f0f0f0 !important;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3) !important;
  }
  .anticon {
    margin-right: 8px !important;
  }
`;

export const InputMaskStyled = styled(ReactInputMask)`
  width: 100%;
  font-family: 'Poppins',sans-serif !important;
  background: #ffffff;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  padding: 4px 11px;
  margin: 0px;
  box-shadow: none;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  position: relative;
  display: inline-block;
  transition: all 0.2s;
`;
