export const FETCH_ANTECIPACAO_GET_DATA = 'FETCH_ANTECIPACAO_GET_DATA'
export const FETCH_ANTECIPACAO_GET_SUCCESS = 'FETCH_ANTECIPACAO_GET_SUCCESS'
export const FETCH_ANTECIPACAO_GET_FAILURE = 'FETCH_ANTECIPACAO_GET_FAILURE'
export const FETCH_ANTECIPACAO_POST_DATA = 'FETCH_ANTECIPACAO_POST_DATA'
export const FETCH_ANTECIPACAO_POST_SUCCESS = 'FETCH_ANTECIPACAO_POST_SUCCESS'
export const FETCH_ANTECIPACAO_POST_FAILURE = 'FETCH_ANTECIPACAO_POST_FAILURE'
export const RESET_URL_DATA = 'RESET_URL_DATA'
export const FETCH_ANTECIPACAO_DELETE_CONTA_DATA = 'FETCH_ANTECIPACAO_DELETE_CONTA_DATA'
export const FETCH_ANTECIPACAO_DELETE_CONTA_SUCCESS = 'FETCH_ANTECIPACAO_DELETE_CONTA_SUCCESS'
export const FETCH_ANTECIPACAO_DELETE_CONTA_FAILURE = 'FETCH_ANTECIPACAO_DELETE_CONTA_FAILURE'
export const FETCH_ANTECIPACAO_EMPRESTIMOS_DATA = 'FETCH_ANTECIPACAO_EMPRESTIMOS_DATA'
export const FETCH_ANTECIPACAO_EMPRESTIMOS_SUCCESS = 'FETCH_ANTECIPACAO_EMPRESTIMOS_SUCCESS'
export const FETCH_ANTECIPACAO_EMPRESTIMOS_FAILURE = 'FETCH_ANTECIPACAO_EMPRESTIMOS_FAILURE'