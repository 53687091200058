import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, Select, Radio, message, Image, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { ButtonConcluir, CustomConteinerFormModal, CustomConteinerFormModalDiv, CustomFormModalSelect, CustomFormModalSelectConta, CustomModal, InputMaskStyled } from './styled';
import { bancoIDParaImagens } from '../../styles/globalInterfaces';
import { AntecipacaoPostApi } from '../../store/target-api/api';
import { DeleteOutlined, LoadingOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { fetchAntecipacaoDeleteConta, fetchAntecipacaoGet } from '../../store/states/antecipacao/actions';

type AntecipacaoModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

interface Banco {
  conta_id: number;
  banco_id: number;
  banco_ref: string;
  agencia: string;
  agencia_digito: string | null;
  conta: string;
  conta_digito: string | null;
  tipo_documento: string;
  numero_documento: string;
  is_default: boolean;
}

interface Empresa {
  empresa_id: number;
  empresa_nome: string;
  documento_tipo: string;
  documento_numero: string;
  email: string;
  Bancos: Array<Banco>;
  endereco: {
    rua: string;
    complemento: string | null;
    bairro: string;
    numero: string;
    cidade: string;
    estado: string;
    codigo_postal: string;
  } | null;
}

const AntecipacaoModal: React.FC<AntecipacaoModalProps> = ({ isVisible, onClose }) => {
  const [selectedEmpresa, setSelectedEmpresa] = useState<Empresa | null>(null);
  const [selectedBanco, setSelectedBanco] = useState<Banco | null>(null);
  const [bancoIsSelected, setBancoIsSelected] = useState(false);
  const [documentType, setDocumentType] = useState('cpf');
  const [isAddingNewAccount, setIsAddingNewAccount] = useState(false);
  const antecipacao = useSelector((state: RootState) => state.antecipacao.data_get.data || []);
  const bancos = useSelector((state: RootState) => state.banco.data);
  const loadingDelete = useSelector((state: RootState) => state.antecipacao.loading_delete);
  const loadingGet = useSelector((state: RootState) => state.antecipacao.loading_get);
  const [form] = Form.useForm();
  const [loadingPost, setLoadingPost] = useState(false);
  const dispatch = useDispatch();

  const contasBancarias = useMemo(() => {
    return antecipacao.length > 0 ? antecipacao.find((element: any) => element.empresa_id === selectedEmpresa?.empresa_id)?.Bancos : [];
  }, [antecipacao, selectedEmpresa]);

  useEffect(() => {
    if (isVisible && !selectedEmpresa) {
      form.resetFields(['empresa', 'select-conta']);
    }
    if (isVisible && !selectedBanco) {
      form.resetFields(['select-conta']);
    }
  }, [antecipacao, contasBancarias, isVisible, form, selectedEmpresa, selectedBanco]);

  useEffect(() => {
    if (selectedEmpresa && !bancoIsSelected) {
      form.setFieldsValue({
        rua: selectedEmpresa.endereco?.rua ?? '',
        complemento: selectedEmpresa.endereco?.complemento ?? '',
        bairro: selectedEmpresa.endereco?.bairro ?? '',
        numero: selectedEmpresa.endereco?.numero ?? '',
        cidade: selectedEmpresa.endereco?.cidade ?? '',
        estado: selectedEmpresa.endereco?.estado ?? '',
        codigo_postal: selectedEmpresa.endereco?.codigo_postal ?? '',
      });
    }

    if (selectedEmpresa) {
      form.setFieldsValue({
        codigo_banco: selectedBanco?.banco_id ?? '',
        agencia: selectedBanco?.agencia ?? '',
        agencia_digito: selectedBanco?.agencia_digito ?? '',
        conta: selectedBanco?.conta ?? '',
        conta_digito: selectedBanco?.conta_digito ?? '',
        numero_documento: selectedBanco?.numero_documento ?? '',
      });
    }
    setDocumentType((selectedBanco?.tipo_documento ?? 'cpf').toLowerCase());
  }, [bancoIsSelected, selectedEmpresa, form, selectedBanco]);

  const handleClose = () => {
    setSelectedEmpresa(null);
    form.resetFields();
    form.resetFields(['empresa', 'selected-banco']);
    setSelectedBanco(null);
    setIsAddingNewAccount(false);
    setLoadingPost(false);
    dispatch(fetchAntecipacaoGet());
    onClose();
  };

  const fetchEndereco = async (cep: string) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      if (!response.ok) throw new Error('Erro ao buscar o endereço');

      const data = await response.json();
      if (data) {
        form.setFieldsValue({
          rua: data.logradouro || '',
          bairro: data.bairro || '',
          cidade: data.localidade || '',
          estado: data.uf || '',
        });
      } else {
        message.warning('Endereço não encontrado para este CEP.');
      }
    } catch (error) {
      console.error(error);
      message.error('Falha ao buscar o endereço. Tente novamente.');
    }
  };

  const handleCepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let cep = e.target.value.replace(/\D/g, '');
    if (cep.length > 8) cep = cep.slice(0, 8);

    if (cep.length < 8) {
      form.setFieldsValue({
        rua: '',
        bairro: '',
        cidade: '',
        estado: '',
      });
    }

    if (cep.length === 8) {
      fetchEndereco(cep);
    }
  };

  const handleEmpresaChange = (value: unknown) => {
    const empresaSelecionada = antecipacao.length > 0 ? antecipacao.find((empresa: Empresa) => empresa.empresa_id === value) : null;
    setSelectedEmpresa(empresaSelecionada || null);
    setSelectedBanco(null);
    setIsAddingNewAccount(false);
    setBancoIsSelected(false);
    form.setFieldsValue({
      'select-conta': '',
    });
  };

  const handleContaChange = (value: unknown) => {
    if (value === 'add-account') {
      setIsAddingNewAccount(true);
      setSelectedBanco(null);
    } else {
      const contaSelecionada = contasBancarias.find((banco: Banco) => banco.conta_id === value);
      setBancoIsSelected(true);
      setIsAddingNewAccount(false);
      setSelectedBanco(contaSelecionada || null);
    }
  };

  const handleDocumentTypeChange = (e: any) => {
    setDocumentType(e.target.value);
    form.setFieldsValue({ numero_documento: '' });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        const jsonResult = [
          {
            empresa_id: selectedEmpresa?.empresa_id || null,
            Bancos: [
              {
                banco_id: values.codigo_banco || null,
                agencia: values.agencia,
                agencia_digito: values.agencia_digito,
                conta: values.conta,
                conta_digito: values.conta_digito,
                tipo_documento: documentType.toUpperCase(),
                numero_documento: values.numero_documento,
                is_default: selectedBanco?.is_default || false,
              },
            ],
            endereco: {
              rua: values.rua,
              bairro: values.bairro,
              numero: values.numero,
              complemento: values.complemento,
              cidade: values.cidade,
              estado: values.estado,
              codigo_postal: values.codigo_postal,
            },
          },
        ];
        setLoadingPost(true);
        setSelectedEmpresa(null);
        const result = await AntecipacaoPostApi(jsonResult);
        const url = result?.data.generated_url;

        if (url) {
          window.open(url, '_blank');
          setLoadingPost(false);
          handleClose();
        } else {
          console.error('URL não encontrada na resposta da API');
          setLoadingPost(false);
        }
      })
      .catch((errorInfo) => {
        message.error('Falha ao preencher formulário. Tente novamente.');
        setLoadingPost(false);
      });
  };

  const handleDelete = (conta_id: number) => {
    dispatch(fetchAntecipacaoDeleteConta(conta_id));
    if (!loadingDelete) {
      dispatch(fetchAntecipacaoGet());
      handleContaChange('add-account');
    }
  };

  return (
    <CustomModal visible={isVisible} onCancel={handleClose} footer={null} centered>
      {loadingGet || loadingDelete ? (
        <div className="loading-overlay">
          <LoadingOutlined style={{ fontSize: 24, color: '#1890ff' }} spin />
        </div>
      ) : (
        <>
          <CustomFormModalSelect>
            <h2>Selecione a Empresa</h2>
            <Form form={form}>
              <Form.Item name="empresa">
                <Select placeholder="Selecione a empresa" onChange={handleEmpresaChange}>
                  {/* Verifica se 'antecipacao' não está vazio */}
                  {antecipacao.length > 0 ? (
                    antecipacao.map((empresa: Empresa) => (
                      <Select.Option key={empresa.empresa_id} value={empresa.empresa_id}>
                        {empresa.empresa_nome}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="" disabled>
                      Nenhuma empresa disponível
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Form>
          </CustomFormModalSelect>

          {selectedEmpresa && (
            <>
              <h2 className="ant-modal-body-h2">Endereço</h2>
              <CustomConteinerFormModal form={form}>
                <CustomConteinerFormModalDiv>
                  <Form.Item
                    label="Código Postal"
                    name="codigo_postal"
                    style={{ width: '400px' }}
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, informe o CEP.',
                      },
                      {
                        validator: (_, value) => {
                          if (value && value.replace(/\D/g, '').length !== 8) {
                            return Promise.reject(new Error('O CEP deve conter exatamente 8 dígitos.'));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputMaskStyled
                      mask="99999-999"
                      maskChar={null}
                      placeholder="00000-000"
                      value={form.getFieldValue('codigo_postal')}
                      onChange={handleCepChange}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Estado"
                    name="estado"
                    style={{ width: '70px' }}
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, informe o EStado.',
                      },
                    ]}
                  >
                    <Input placeholder="UF" disabled />
                  </Form.Item>
                  <Form.Item
                    label="Cidade"
                    name="cidade"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, informe o EStado.',
                      },
                    ]}
                  >
                    <Input placeholder="Cidade" disabled />
                  </Form.Item>
                  <Form.Item
                    label="Bairro"
                    name="bairro"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, informe o EStado.',
                      },
                    ]}
                  >
                    <Input placeholder="Bairro" disabled />
                  </Form.Item>
                </CustomConteinerFormModalDiv>
                <CustomConteinerFormModalDiv>
                  <Form.Item
                    label="Rua"
                    name="rua"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, informe o Estado.',
                      },
                    ]}
                  >
                    <Input placeholder="Rua" disabled />
                  </Form.Item>
                  <Form.Item
                    label="Número"
                    name="numero"
                    style={{ width: '250px' }}
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, informe o número.',
                      },
                    ]}
                  >
                    <Input placeholder="Número" />
                  </Form.Item>
                  <Form.Item label="Complemento" name="complemento">
                    <Input placeholder="Complemento" />
                  </Form.Item>
                </CustomConteinerFormModalDiv>
              </CustomConteinerFormModal>

              <CustomFormModalSelect>
                <Form form={form}>
                  <h2>Conta Bancária</h2>
                  <CustomFormModalSelectConta>
                    <Form.Item name="select-conta">
                      <Select placeholder="Selecionar conta" onChange={handleContaChange}>
                        <Select.Option key="add-account" value="add-account">
                          <PlusSquareOutlined style={{ fontSize: '14px', marginRight: '11px' }} />
                          Adicionar conta
                        </Select.Option>
                        {contasBancarias.map((conta: Banco) => {
                          const banco = bancos.find((b: any) => b.bancoid === conta.banco_id);
                          const bancoImagem = bancoIDParaImagens[parseInt(banco.bancoref, 10)];

                          return banco ? (
                            <Select.Option key={conta.conta_id} value={conta.conta_id}>
                              {bancoImagem ? <Image width={20} src={bancoIDParaImagens[parseInt(banco.bancoref, 10)]} alt={banco.banconome} preview={false} /> : null}
                              {` ${banco.bancoref} - ${banco.banconome} Conta: ${conta.conta}`}
                            </Select.Option>
                          ) : null;
                        })}
                      </Select>
                    </Form.Item>
                    {selectedBanco && <Button type="text" icon={<DeleteOutlined />} style={{ fontSize: '20px', color: 'red', marginLeft: '30px', marginBottom: '24px' }} onClick={() => handleDelete(selectedBanco?.conta_id)} />}
                  </CustomFormModalSelectConta>
                </Form>
              </CustomFormModalSelect>

              {(selectedBanco || isAddingNewAccount) && (
                <CustomConteinerFormModal form={form}>
                  <CustomConteinerFormModalDiv>
                    <Form.Item label="Banco" name="codigo_banco">
                      <Select placeholder="Selecionar conta">
                        {bancos &&
                          bancos.map((banco: { bancoid: number; banconome: string; bancoref: string }) => (
                            <Select.Option key={banco.bancoid} value={banco.bancoid}>
                              {`${banco.bancoref} - ${banco.banconome}`}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </CustomConteinerFormModalDiv>
                  <CustomConteinerFormModalDiv>
                    <Form.Item
                      label="Agência"
                      name="agencia"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor, informe a agência.',
                        },
                      ]}
                    >
                      <Input
                        placeholder="123456"
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Dígito" name="agencia_digito" style={{ width: '50px' }}>
                      <Input
                        placeholder="x"
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={2}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Conta"
                      name="conta"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor, informe a conta.',
                        },
                      ]}
                    >
                      <Input
                        placeholder="123456"
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Dígito" name="conta_digito" style={{ width: '50px' }}>
                      <Input
                        placeholder="x"
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={2}
                      />
                    </Form.Item>
                  </CustomConteinerFormModalDiv>
                  <CustomConteinerFormModalDiv>
                    <Radio.Group onChange={handleDocumentTypeChange} value={documentType}>
                      <Radio value="cpf">CPF</Radio>
                      <Radio value="cnpj">CNPJ</Radio>
                    </Radio.Group>
                    <Form.Item
                      label="Documento"
                      name="numero_documento"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor, informe o número do documento.',
                        },
                        {
                          validator: (_, value) => {
                            if (documentType === 'cpf' && value && value.replace(/\D/g, '').length !== 11) {
                              return Promise.reject(new Error('O CPF deve conter exatamente 11 dígitos.'));
                            }
                            if (documentType === 'cnpj' && value && value.replace(/\D/g, '').length !== 14) {
                              return Promise.reject(new Error('O CNPJ deve conter exatamente 14 dígitos.'));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputMaskStyled
                        mask={documentType === 'cpf' ? '999.999.999-99' : '99.999.999/9999-99'}
                        placeholder={documentType === 'cpf' ? '000.000.000-00' : '00.000.000/0000-00'}
                        maskChar={null}
                        value={form.getFieldValue('numero_documento')}
                      />
                    </Form.Item>
                  </CustomConteinerFormModalDiv>
                  <CustomFormModalSelect>
                    <ButtonConcluir type="primary" onClick={handleSubmit}>
                      Concluir
                    </ButtonConcluir>
                  </CustomFormModalSelect>
                </CustomConteinerFormModal>
              )}
            </>
          )}
          {loadingPost && (
            <CustomModal visible={loadingPost} onCancel={handleClose} footer={null} centered>
              <h2 className="ant-modal-body-h2">Estamos finalizando os últimos detalhes e te redirecionando para a página de antecipação.</h2>
              <h3 className="ant-modal-body-h2">Aguarde um momento!</h3>
            </CustomModal>
          )}
        </>
      )}
    </CustomModal>
  );
};
export default AntecipacaoModal;
