import { Button, Input } from 'antd';
import ReactInputMask from 'react-input-mask';
import styled from 'styled-components';


export const StyledButtonContainer = styled(Button)`
text-align: center;
margin-top: 20px; // Ajuste a margem conforme necessário
`;

export const StyledInput = styled(Input)`
  width: 555px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #EEEEF6;
`;

export const StyledInputMask = styled(ReactInputMask)`
  width: 555px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #EEEEF6;
`;

export const LoginProposal = styled(Button)`
  background-color: #c9e437;
  width: 555px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 25px;
  color: black;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 20px;
`;

export const ModalDisplay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(37, 47, 100, 0.7), rgba(37, 47, 100, 0.5));
  backdrop-filter: ${({ isOpen }) => (isOpen ? 'blur(10px)' : 'none')};
  z-index: 1000;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;
